import React from "react";

const ModalIT = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Detail My Certificate</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Resource </span>:
              <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/R2LQR4ULU5PR" target="_blank" rel="noopener noreferrer">
                <span className="ft-wt-600 uppercase">Coursera</span>
              </a>
            </div>
            {/* End .col */}
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Langages </span>:
              <span className="ft-wt-600 uppercase">PYTHON</span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/*  Main Project Content Starts */}
        <img src="img/projects/it_support.jpg" alt="Portolio" />
        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalIT;
