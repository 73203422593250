import React from "react";

const experienceContent = [
  {
    year: "   2021 - 2022 ",
    position: " Web Developer",
    compnayName: "Telkom Akses Indonesia",
    details: `  I work mainly on the backend and frontend, there I have a lot
    making API , customer dashboard chart, help desk dashboard performance
    , dashboard ticketing and BOT Telegram`,
  },
  {
    year: "2017 - 2020",
    position: " UI/UX Designer",
    compnayName: "Telkom Akses Indonesia",
    details: `In design section, I made a lot of logos, microsoft power point templates,
    dashboard mockup, icon, animation and electronic device vector`,
  },
  {
    year: "2022",
    position: "Logo Design",
    compnayName: "99Design",
    details: `Here mostly I take part in logo design contests and web-based designs
    adobe photoshop and I have won many contests`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
