import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Fiar" },
  { meta: "last name", metaInfo: "Perdana" },
  { meta: "Age", metaInfo: "23 Years" },
  { meta: "Nationality", metaInfo: "Indonesian" },
  { meta: "Freelance", metaInfo: "Available" },
  { meta: "Address", metaInfo: "Bojong Nangka, Kelapa Dua, Tangerang Regency, Banten 15810" },
  { meta: "phone", metaInfo: "081284799562" },
  { meta: "Email", metaInfo: "perdanafiar@gmail.com" },
  { meta: "Skype", metaInfo: "fiar.perdana" },
  { meta: "langages", metaInfo: "Indonesia, English" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
